import { guid } from '../../../server/helper'

// These enums must be synced with relevant SCSS variables
export const ADBLOCKER_ELEMENT_PLACEHOLDER = {
  BANNER_SPACE_ALLOCATOR: 'guid-cacc1b1e',
  MOBILE_MODIFIER: 'guid-8288d171',
  TABLET_MODIFIER: 'guid-36bfaee5',
  DESKTOP_SM_MODIFIER: 'guid-5fc2ac13',
  DESKTOP_SM2_MODIFIER: 'guid-ba51e25d',
  DESKTOP_SM3_MODIFIER: 'guid-20dcc9fc',
  DESKTOP_MD_MODIFIER: 'guid-7550d6a8',
  DESKTOP_LG_MODIFIER: 'guid-64f63bdb',
  DESKTOP_XL_MODIFIER: 'guid-f81b8d0a',
  ABOVE_DESKTOP_XL_MODIFIER: 'guid-b4c08f36',
  SPONSORED_ARTICLE_INDICATOR: 'guid-73d145d9'
}

const ADBLOCKER_ELEMENT_DYNAMIC_VALUE_TEMPLATE = {
  [ADBLOCKER_ELEMENT_PLACEHOLDER.BANNER_SPACE_ALLOCATOR]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.MOBILE_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.TABLET_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM2_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM3_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_MD_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_LG_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_XL_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.ABOVE_DESKTOP_XL_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.SPONSORED_ARTICLE_INDICATOR]: null
}

function generateUniqueId(existingIds = []) {
  let id
  do {
    id = `guid-${guid().match(/[\d\w]*/)[0]}`
  } while (existingIds.includes(id))

  return id
}

function fillDynamicValues(obj) {
  const usedIds = []
  const resultObject = {}

  for (const key in obj) {
    const uniqueId = generateUniqueId(usedIds)
    resultObject[key] = uniqueId
    usedIds.push(uniqueId)
  }

  return resultObject
}

export const ADBLOCKER_ELEMENT_DYNAMIC_VALUE_BY_PLACEHOLDER = fillDynamicValues(
  ADBLOCKER_ELEMENT_DYNAMIC_VALUE_TEMPLATE
)
